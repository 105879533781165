// src/components/ui/Alert.js
import React from 'react';

const Alert = ({ children, className }) => {
  return (
    <div className={`p-4 border rounded-lg ${className}`}>
      {children}
    </div>
  );
};

const AlertDescription = ({ children, className }) => {
  return (
    <div className={`text-sm ${className}`}>
      {children}
    </div>
  );
};

export { Alert, AlertDescription };